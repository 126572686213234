import '../styles/login.scss';

import $ from 'jquery';
// create global $ and jQuery variables
global.$ = global.jQuery = $;

import 'jquery-ui';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

// import '/web/bundles/mopabootstrap/bootstrap/dist/js/bootstrap.js';
import 'bootstrap';

import 'jquery.cookie';
import 'jquery.uniform';
import 'jquery-backstretch';

import './components/script';

import App from './components/app';
global.App = App;